/*
 * Copyright 2024-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React, { useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { authUtils } from 'utils';

import { TopologyAction } from 'enums/topologyAction';
import { TopologyDetails } from 'types';

import { LogsModal } from '../LogsModal';

type LogsMenuItemProps = {
  readonly topology: TopologyDetails;
};
export const LogsMenuItem: React.FC<LogsMenuItemProps> = ({ topology }) => {
  const [action, setAction] = useState<TopologyAction>(
    TopologyAction.no_action_selected
  );

  const currentUser = authUtils.getLoggedInUser();

  if (
    currentUser === null ||
    (currentUser.role !== 'admin' && currentUser.role !== 'support')
  ) {
    return null;
  } else {
    return (
      <>
        <Dropdown
          item
          trigger={
            <span>
              <Icon name='file alternate' />
              Logs
            </span>
          }
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setAction(TopologyAction.create)}>
              Creation
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setAction(TopologyAction.delete)}>
              Deletion
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setAction(TopologyAction.extend_duration)}
            >
              Extend duration
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                setAction(TopologyAction.send_topology_expiration_email)
              }
            >
              Send topology expiration email
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setAction(TopologyAction.external_router_config)}
            >
              External router config
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setAction(TopologyAction.apply_milestone)}
            >
              Apply milestone
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <LogsModal
          topologyAction={action}
          onClose={() => setAction(TopologyAction.no_action_selected)}
          isOpen={action !== TopologyAction.no_action_selected}
          topologyUuid={topology.uuid}
        />
      </>
    );
  }
};
