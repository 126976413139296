import { map } from 'lodash';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dimmer, Loader, Table } from 'semantic-ui-react';

import { SelectionType } from 'components/ContactsTable/SelectionType';
import { CustomTableHeaderRow } from 'components/CustomTableHeaderRow';
import { Pagination } from 'components/TablePagination';
import { Lab, PaginationFilters } from 'types';

import { setSelectedLabs } from 'features/labs';
import { RootState } from 'Reducers/contactReducer';
import { LabRow } from './LabRow';

type Props = {
  readonly labs: Lab[];
  readonly displayedColumns: string[];
  readonly selectionType: SelectionType;
  readonly isLoading: boolean;
  readonly onDelete: (lab: Lab) => void;
  readonly activePage: number;
  readonly totalPages: number;
  readonly pagination: PaginationFilters;
  readonly setPagination: React.Dispatch<
    React.SetStateAction<PaginationFilters>
  >;
};

export const LabsTable: React.FC<Props> = memo(
  ({
    labs,
    displayedColumns,
    isLoading,
    onDelete,
    activePage,
    totalPages,
    pagination,
    selectionType,
    setPagination
  }) => {
    const dispatch = useDispatch();
    const labsState = useSelector((state: RootState) => state.lab);
    const selectedLabs = labsState.selected;
    const allOrAnySelected = () =>
      (selectedLabs.length === labs.length && labs.length !== 0) ||
      selectedLabs.length > 0;

    const handleSelectAll = async () => {
      const tempContacts =
        selectedLabs.length === labs.length || selectedLabs.length > 0
          ? []
          : [...labs];
      dispatch(setSelectedLabs(tempContacts));
    };
    const header = (
      <Table.Header>
        <CustomTableHeaderRow
          selectionType={selectionType}
          indeterminate={allOrAnySelected()}
          onChange={() => handleSelectAll()}
          displayedColumns={displayedColumns}
        />
      </Table.Header>
    );
    const footer = (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan='12'>
            <Pagination
              activePage={activePage}
              totalPages={totalPages}
              onPaginationChange={page =>
                setPagination({
                  ...pagination,
                  activePage: page as number
                })
              }
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
    if (isLoading) {
      return (
        <Dimmer.Dimmable as={Table} dimmed>
          {header}
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Dimmer.Inner active inverted>
                  <Loader active>Loading...</Loader>
                </Dimmer.Inner>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          {footer}
        </Dimmer.Dimmable>
      );
    }
    return (
      <Table celled striped unstackable className='cl-topologies-table'>
        {header}
        <Table.Body>
          {map(labs, lab => {
            return (
              <LabRow
                key={lab.uuid}
                lab={lab}
                displayedColumns={displayedColumns}
                selectionType={selectionType}
                onDelete={onDelete}
              />
            );
          })}
        </Table.Body>
        {footer}
      </Table>
    );
  }
);
