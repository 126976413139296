import {
  DatesByPage,
  FiltersByPage,
  ToggleObject,
  Topology,
  TopologyFieldNames
} from 'types';

import * as actions from './actionsTypes';

export const setDepartments = (departments: string[]) => ({
  type: actions.DEPARTMENTS,
  payload: departments
});

export const setTopologies = (topologies: Topology[]) => ({
  type: actions.TOPOLOGIES,
  payload: topologies
});

export const setDatesInputRange = (datesByPage: DatesByPage) => ({
  type: actions.DATEINPUT,
  payload: datesByPage
});

export const setReportFilters = (filtersByPage: FiltersByPage) => ({
  type: actions.FILTERINPUT,
  payload: filtersByPage
});

export const setTopActiveMarker = (value: number) => ({
  type: actions.TOPACTIVE,
  payload: value
});

export const toggleUI = (value: ToggleObject) => ({
  type: actions.TOGGLEUI,
  payload: value
});

export const setTopologyFieldName = (fieldValues: TopologyFieldNames) => ({
  type: actions.FIELDVALUES,
  payload: fieldValues
});
