import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Segment, Sidebar } from 'semantic-ui-react';

import { isNullOrUndefined } from 'utils/utilFunctions';

import { RootState } from 'Reducers/contactReducer';

import './styles.less';

type Props = {
  readonly sidebarChilden?: React.ReactNode;
  readonly visible: boolean;
  readonly onVisibleChange: (visible: boolean) => void;
};

export const InfoSidebar: React.FC<Props> = memo(
  ({ sidebarChilden, visible, onVisibleChange }) => {
    const isContent = useSelector((state: RootState) => state.lab.isContent);
    useEffect(() => {
      let pageOuterElement = document.querySelector('.cl-page-outer');
      const MainSidebarElement = document.querySelector('.cl-main-sidebar');
      if (MainSidebarElement) {
        pageOuterElement = document.querySelector(
          '.cl-page-outer'
        ) as HTMLElement;
        const MainSidebarWidth = MainSidebarElement.clientWidth;
        if (visible) {
          const LabSidebarElement = document.querySelector(
            '.info-content-sidebar'
          );
          if (pageOuterElement && LabSidebarElement) {
            (pageOuterElement as HTMLElement).style.width = `calc(100% - ${MainSidebarWidth +
              LabSidebarElement.clientWidth}px)`;
          }
        } else {
          if (pageOuterElement) {
            (pageOuterElement as HTMLElement).style.width = `calc(100% - ${MainSidebarWidth}px)`;
          }
        }
      }
    }, [visible]);
    if (!visible) return <></>;

    return (
      <Sidebar
        as={Segment}
        data-testid='lab-sidebar'
        className='info-content-sidebar'
        animation='push'
        direction='right'
        visible={visible}
        width='very wide'
        inverted
      >
        <div className='container'>
          <div className='header-text'>
            {!isNullOrUndefined(isContent)
              ? isContent
                ? 'Lab Contents'
                : 'Lab Details'
              : ''}
            <Button
              className='button-collapse'
              icon='sign-in'
              basic
              inverted
              onClick={() => onVisibleChange(!visible)}
            />
          </div>
          {sidebarChilden}
        </div>
      </Sidebar>
    );
  }
);
