import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'semantic-ui-react';

import { setCurrentPage } from 'features/labs';

type Props = {
  readonly filter: string;
  readonly setFilter: (value: string) => void;
};

export const LabSearch: React.FC<Props> = memo(({ filter, setFilter }) => {
  const [filterInput, setFilterInput] = React.useState<string>(filter);

  const dispatch = useDispatch();
  const dispatchQuery = () => {
    dispatch(setCurrentPage(1));
  };

  return (
    <Input
      type='search'
      focus
      placeholder='Search labs. Hit Enter to search'
      className='search-input'
      value={filterInput}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterInput(e.target.value);
      }}
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          setFilter(filterInput);
          dispatchQuery();
        }
      }}
    />
  );
});
