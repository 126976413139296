import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Icon, List, Popup, Radio, Table } from 'semantic-ui-react';
import {
  formatUTCDate,
  getUTCFromNow,
  interpolateRoute,
  notifier,
  TopologyStatus
} from 'utils';
import { calculateLabProgress } from 'utils/labs';

import { SelectionType } from 'components/ContactsTable/SelectionType';
import { StateLabel } from 'components/StateLabel';
import { TextLink } from 'components/TextLink';
import { routes } from 'constants/routing';
import { Lab, Topology } from 'types';

import { setSelectedLabs } from 'features/labs';
import { RootState } from 'Reducers/contactReducer';

type Props = {
  readonly lab: Lab;
  readonly displayedColumns: string[];
  readonly selectionType: SelectionType;
  readonly onDelete: (lab: Lab) => void;
};

function LabSelector(props: {
  selectionType: SelectionType;
  selected: boolean;
  onSelect: () => void;
}) {
  if (props.selectionType === 'multi') {
    return (
      <Checkbox
        checked={props.selected}
        type='checkbox'
        onChange={props.onSelect}
      />
    );
  } else {
    return (
      <Radio checked={props.selected} type='radio' onChange={props.onSelect} />
    );
  }
}

function aggregateTopologyState(topologies: Topology[]): string {
  const states = topologies.map(topology => topology.state);
  if (states.some(state => state === TopologyStatus.ERROR)) {
    return TopologyStatus.ERROR;
  } else if (
    states.some(state => state === TopologyStatus.SHUTDOWN_IN_PROGRESS)
  ) {
    return TopologyStatus.SHUTDOWN_IN_PROGRESS;
  } else if (
    states.some(state => state === TopologyStatus.DEPLOY_IN_PROGRESS)
  ) {
    return TopologyStatus.DEPLOY_IN_PROGRESS;
  } else if (states.every(state => state === TopologyStatus.DOWN)) {
    return TopologyStatus.DOWN;
  } else if (states.every(state => state === TopologyStatus.UP)) {
    return TopologyStatus.UP;
  } else {
    return TopologyStatus.UNKNOWN;
  }
}

export const LabRow: React.FC<Props> = memo(
  ({ lab, displayedColumns, selectionType, onDelete }) => {
    const dispatch = useDispatch();
    const labsState = useSelector((state: RootState) => state.lab);
    const selectedLabs = labsState.selected;
    const checked = () => selectedLabs.indexOf(lab) !== -1;
    const selectLab = (): void => {
      if (!checked()) {
        if (selectionType === 'single') {
          dispatch(setSelectedLabs([lab]));
        } else if (selectionType === 'multi') {
          dispatch(setSelectedLabs(selectedLabs.concat(lab)));
        }
      } else if (selectionType === 'multi') {
        dispatch(setSelectedLabs(selectedLabs.filter(l => l !== lab)));
      }
    };
    return (
      <Table.Row key={lab.uuid}>
        {selectionType !== 'none' && (
          <Table.Cell collapsing key={'selector'}>
            <LabSelector
              selectionType={selectionType}
              selected={checked()}
              onSelect={selectLab}
            />
          </Table.Cell>
        )}
        {displayedColumns.map(col => {
          switch (col) {
            case 'lab name':
              return (
                <Table.Cell key={col} collapsing>
                  <div className='topology-name-cell-content'>
                    <div>
                      <TextLink
                        to={interpolateRoute(routes.lab.path, {
                          uuid: lab.uuid
                        })}
                      >
                        {lab.label}
                      </TextLink>
                    </div>
                  </div>
                </Table.Cell>
              );
            case 'region':
              return (
                <Table.Cell key={col} collapsing>
                  {lab.region.label}
                </Table.Cell>
              );
            case 'creation time':
              return (
                <Table.Cell key={col} collapsing>
                  <div>{formatUTCDate(lab.createdAt)}</div>
                  <div className='text-small'>
                    {getUTCFromNow(lab.createdAt)}
                  </div>
                </Table.Cell>
              );
            case 'expiration time':
              return (
                <Table.Cell key={col} collapsing>
                  <div>{formatUTCDate(lab.expiresAt)}</div>
                  <div className='text-small'>
                    {getUTCFromNow(lab.expiresAt)}
                  </div>
                </Table.Cell>
              );
            case 'created by':
              return <Table.Cell key={col}>{lab.createdBy.name}</Table.Cell>;
            case 'created for':
              return <Table.Cell key={col}>{lab.createdFor.name}</Table.Cell>;
            case 'status':
              return (
                <Table.Cell className='status-cell' key={col}>
                  {lab.topologies ? (
                    <StateLabel
                      state={aggregateTopologyState(lab.topologies)}
                    />
                  ) : (
                    <span>-</span>
                  )}
                </Table.Cell>
              );
            case 'progress':
              return (
                <Table.Cell textAlign='center' key={col}>
                  {calculateLabProgress(lab)}%
                </Table.Cell>
              );
            case 'topology':
              return (
                <Table.Cell key={col}>
                  <div>
                    {lab.topologies ? (
                      <List selection relaxed='very'>
                        {lab.topologies.map(topology => (
                          <List.Item key={topology.uuid}>
                            <TextLink
                              to={interpolateRoute(routes.topology.path, {
                                id: topology.uuid
                              })}
                            >
                              {topology.name}
                            </TextLink>
                          </List.Item>
                        ))}
                      </List>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </Table.Cell>
              );
            case 'actions':
              return (
                <Table.Cell
                  collapsing
                  textAlign='center'
                  className='actions-cell'
                  key={col}
                >
                  <Popup
                    content='Delete'
                    position='top center'
                    trigger={
                      <Icon
                        link
                        name='trash alternate'
                        onClick={() => onDelete(lab)}
                      />
                    }
                  />
                </Table.Cell>
              );
            default:
              notifier.error({ message: `Unknown column: ${col}` });
          }
          return <></>;
        })}
      </Table.Row>
    );
  }
);
