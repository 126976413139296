import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader, StrictBreadcrumbSectionProps } from 'semantic-ui-react';

import { LabSidebar } from 'components/LabSidebar';
import { routes } from 'constants/routing';
import { useGetLab } from 'hooks/useLabTemplate';
import { useRouter } from 'hooks/useRouter';
import { MainLayout } from 'layouts';
import { Lab } from 'types';

import { setCurrentLab, setSidebarIsContentType } from 'features/labs';
import { LabDetailView } from './LabDetailView';

export const LabView: React.FC = memo(() => {
  const dispatch = useDispatch();
  const [visible, setSideBarVisibility] = useState<boolean>(false);
  const [content, setSideBarContent] = useState<boolean>(false);
  const router = useRouter();
  const uuid: string = (router.match.params as { uuid: string }).uuid;
  const { data, isLoading } = useGetLab(uuid);
  const lab = useMemo(() => (data ? data : ({} as Lab)), [data]);
  useEffect(() => {
    dispatch(setSidebarIsContentType(content));
    dispatch(setCurrentLab(lab));
    if (lab && lab.topologies) {
      setSideBarVisibility(
        lab.topologies.length > 0 &&
          lab.topologies.every(topology => topology.state === 'up')
      );
    }
  }, [lab, content, dispatch]);
  const breadcrumbSections: StrictBreadcrumbSectionProps[] = [
    {
      content: routes.labs.label,
      href: routes.labs.path
    },
    {
      content: isLoading ? (
        <Loader active inline='centered' size='tiny' />
      ) : lab ? (
        lab?.label
      ) : null,
      active: true
    }
  ];
  return (
    <MainLayout
      breadcrumbSections={breadcrumbSections}
      visible={visible}
      onVisibleChange={setSideBarVisibility}
      sidebarChilden={<LabSidebar />}
    >
      <LabDetailView
        isLoading={isLoading}
        lab={lab}
        visible={visible}
        onVisibleChange={setSideBarVisibility}
        onContentChange={setSideBarContent}
      />
    </MainLayout>
  );
});
