import { isEmpty } from 'lodash';

import { Lab, LabGuideUrl } from 'types';

import { isNullOrUndefined } from './utilFunctions';

// The purpose of this function is to ungroup topologies
// from labs. This is useful when we want to display
// topologies as separate entities.
// As opposed to displaying topologies associated with a lab
// in the same row, forexample a lab with 3 topologies,
// the table row  would look very cluttered with the topology details,
export const ungroupByTopologies = (
  labs: Lab[],
  createdForId: number[] = [],
  createdById: number[] = []
): Lab[] => {
  if (isEmpty(labs)) {
    return [];
  }
  let response = labs;
  if (!isEmpty(createdForId) && !isEmpty(createdById)) {
    response = response.filter(
      (lab: Lab) =>
        createdById.includes(lab.createdBy.id) &&
        createdForId.includes(lab.createdFor.id)
    );
  } else if (!isEmpty(createdForId)) {
    response = response.filter((lab: Lab) =>
      createdForId.includes(lab.createdFor.id)
    );
  } else if (!isEmpty(createdById)) {
    response = response.filter((lab: Lab) =>
      createdById.includes(lab.createdBy.id)
    );
  }
  return response;
};

export const getLabTemplateUrls = (lab: Lab): LabGuideUrl[] => {
  if (isNullOrUndefined(lab)) {
    return [];
  }
  return lab.labTemplate?.labGuideUrls;
};

export const calculateLabProgress = (lab: Lab) => {
  return lab.progress && lab.progress.currentPage > 0
    ? ((lab.progress.currentPage / lab.progress.totalPages) * 100).toFixed(0)
    : 0;
};
