import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm, Dropdown, Icon } from 'semantic-ui-react';
import { authUtils, interpolateRoute, notifier, reloadData } from 'utils';

import { topologiesApi } from 'api/topology';
import { filterOptionsByRole } from 'components';
import { routes } from 'constants/routing';
import { useLabMutations } from 'hooks/useLabTemplate';
import { Lab } from 'types';

import { setSelectedLabs } from 'features/labs';
import { RootState } from 'Reducers/contactReducer';

import './styles.less';

export const LabActionContainer: React.FC = () => {
  const dispatch = useDispatch();
  const labsState = useSelector((state: RootState) => state.lab);
  const selectedLabs = labsState.selected;

  const dropdownDisable = selectedLabs.length === 0;
  const extendLabDisable =
    selectedLabs &&
    selectedLabs.length === 1 &&
    selectedLabs.every(
      lab =>
        lab.topologies.length > 0 &&
        lab.topologies.every(topology => topology.state === 'up')
    );
  const [confirmDeleteModelOpen, setConfirmDeleteModelOpen] = useState(false);
  const user = authUtils.getLoggedInUser();
  const { useDeleteLab } = useLabMutations();
  const extendDuration = async (duration: string) => {
    try {
      selectedLabs.forEach(lab => {
        lab.topologies.forEach(
          async topology =>
            await topologiesApi.extendDuration(topology.uuid, duration)
        );
        reloadData();
        notifier.success({
          message: `Duration successfully extended for ${lab.label}`
        });
      });
    } catch (err) {
      notifier.requestFailed(err);
    }
  };
  const onDelete = () => {
    if (!selectedLabs) {
      return;
    }
    selectedLabs.forEach(async (lab: Lab) => {
      try {
        useDeleteLab.mutate(lab);
        dispatch(setSelectedLabs([]));
      } catch (err) {
        notifier.requestFailed(err);
      }
      setConfirmDeleteModelOpen(false);
    });
  };

  return (
    <div>
      <Dropdown
        text='Actions'
        button
        labeled
        basic
        floating
        className='icon link item extend-dropdown'
        direction='left'
      >
        <Dropdown.Menu className='right'>
          <Dropdown.Menu scrolling>
            <Dropdown
              item
              disabled={!extendLabDisable}
              icon={null}
              trigger={
                <span>
                  <Icon name='stopwatch' />
                  Extend Duration
                </span>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Header icon='stopwatch' content='Select Duration' />
                <Dropdown.Divider />
                {filterOptionsByRole(user).map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => extendDuration(option.value as string)}
                    {...option}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown.Item
              disabled={dropdownDisable}
              onClick={() => setConfirmDeleteModelOpen(true)}
              icon='delete'
              text='Delete'
            />
            <Dropdown.Item
              onClick={() =>
                (window.location.href = interpolateRoute(
                  routes.createLabs.path
                ))
              }
              icon='plus circle'
              text='Create New Lab'
            />
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
      <Confirm
        size='mini'
        header='Delete lab'
        content={
          <p className='delete-lab-dialog'>
            This action is irreversible. Are you sure you want to delete labs?
            <ul>
              <b>
                {selectedLabs.map(c => (
                  <li key={c.uuid}>{c.label}</li>
                ))}
              </b>
            </ul>
          </p>
        }
        open={confirmDeleteModelOpen}
        onCancel={() => setConfirmDeleteModelOpen(false)}
        onConfirm={() => onDelete()}
        confirmButton='Delete'
      />
    </div>
  );
};
