/*
 * Copyright 2024-present, Apstra, Inc. All rights reserved.
 *
 * This source code is licensed under End User License Agreement found in the
 * LICENSE file at http://www.apstra.com/eula
 */
import React, { memo, useState } from 'react';
import { useMutation } from 'react-query';
import { Header } from 'semantic-ui-react';
import { notifier } from 'utils';

import { topologiesApi } from 'api/topology';
import { EditableInput } from 'components/EditableInput/EditableInput';
import { Topology, UpdateTopology } from 'types';

interface TopologyNameHeaderProps {
  topology: Topology;
}

export const TopologyNameHeader: React.FC<TopologyNameHeaderProps> = memo(
  ({ topology }) => {
    const [topologyName, setTopologyName] = useState(
      topology.label || topology.name
    );
    const mutation = useMutation(
      (payload: UpdateTopology) => {
        setTopologyName(payload.label ?? '');
        return topologiesApi.update(topology.uuid, payload);
      },
      {
        onError: e => {
          setTopologyName(topology.label || topology.name);
          notifier.requestFailed(e);
        }
      }
    );

    const onAcceptChanges = (newValue: string) => {
      mutation.mutate({ label: newValue });
    };

    return (
      <Header as={'h2'}>
        <EditableInput
          text={topologyName}
          onAcceptChanges={onAcceptChanges}
          error={mutation.isError}
          zIndex={1}
        />
      </Header>
    );
  }
);
