import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Lab } from 'types';

type RequestState = 'idle' | 'failed' | 'succeeded' | 'loading' | 'update';
export type ContactsFilters = {
  readonly text: string;
  readonly role: string | null;
};
export interface LabsState {
  labs: Lab[];
  lab: Lab | undefined;
  isContent: boolean;
  currentPage: number;
  error: string;
  limit: number;
  requestState: RequestState;
  selected: Lab[];
  totalLabs: number;
  totalPages: number;
}
const initialState: LabsState = {
  labs: [],
  lab: undefined,
  isContent: false,
  currentPage: 1,
  error: '',
  limit: 10,
  requestState: 'idle',
  selected: [],
  totalLabs: 0,
  totalPages: 1
};

export const labsSlice = createSlice({
  name: 'labs',
  initialState,
  reducers: {
    setCurrentPage: (state: LabsState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSelectedLabs: (state: LabsState, action: PayloadAction<Lab[]>) => {
      state.selected = action.payload;
    },
    setCurrentLab: (
      state: LabsState,
      action: PayloadAction<Lab | undefined>
    ) => {
      state.lab = action.payload;
    },
    setSidebarIsContentType: (
      state: LabsState,
      action: PayloadAction<boolean>
    ) => {
      state.isContent = action.payload;
    }
  }
});

export const {
  setSelectedLabs,
  setCurrentPage,
  setCurrentLab,
  setSidebarIsContentType
} = labsSlice.actions;

export default labsSlice.reducer;
