export const host = window.location.protocol + '//' + window.location.host;

const LABGUIDE_DEFAULT_VERSION = '5.0.0';

export const JuniperLabGuideDoc = () => {
  return (
    host +
    `/labguide/Cloudlabs/${LABGUIDE_DEFAULT_VERSION}/lab1-junos/lab1-junos-0_intro.html`
  );
};

export const GettingStartedLabGuideDoc = () => {
  return host + `/labguide/Cloudlabs/${LABGUIDE_DEFAULT_VERSION}/index.html`;
};

export const MultiLabGuideDoc = () => {
  return (
    host +
    `/labguide/Cloudlabs/${LABGUIDE_DEFAULT_VERSION}/lab1-multi/lab1-multi-0_intro.html`
  );
};

export const FreeformLabGuideDoc = () => {
  return (
    host +
    `/labguide/Cloudlabs/${LABGUIDE_DEFAULT_VERSION}/labff/labff-0_intro.html`
  );
};

export const FreeformRALabGuideDoc = () => {
  return (
    host +
    `/labguide/Cloudlabs/${LABGUIDE_DEFAULT_VERSION}/labff_ra/lab_ff_ra_0_intro.html`
  );
};

export const ApiLabGuideDoc = () => {
  return (
    host +
    `/labguide/Cloudlabs/${LABGUIDE_DEFAULT_VERSION}/labapi/labapi-0.html`
  );
};

export const TerraformJunosLabGuideDoc = () => {
  return (
    host +
    `/labguide/Cloudlabs/${LABGUIDE_DEFAULT_VERSION}/lab-terraform/labTF-1-intro.html`
  );
};

export const reloadData = () => {
  setTimeout(() => {
    window.location.reload();
  }, 1000);
};

export const getBaseUrl = (): string => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};
