import React, { memo, useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import { notifier, paginationUtils } from 'utils';
import { ungroupByTopologies } from 'utils/labs';

import { SelectionType } from 'components/ContactsTable/SelectionType';
import { LabsTable } from 'components/LabsTable';
import { getActiveItems } from 'components/TopologyReports/UsageTable/ReportFilterHelpers';
import { useFilterGetAllLabs, useLabMutations } from 'hooks/useLabTemplate';
import { Lab, PaginationFilters } from 'types';

import './styles.less';

type Props = {
  readonly displayedColumns: string[];
  readonly selectionType: SelectionType;
  readonly limit: number;
  readonly filter: string | null;
  readonly offset?: number;
  readonly createdForId?: number[];
  readonly createdById?: number[];
};

const filterItems = (
  labs: Lab[],
  pagination: PaginationFilters
): {
  total: number;
  items: Lab[];
} => {
  return {
    total: labs.length,
    items: getActiveItems(pagination, labs)
  };
};

export const Labs: React.FC<Props> = memo(
  ({
    displayedColumns,
    selectionType,
    createdForId,
    createdById,
    limit,
    filter
  }) => {
    const [pagination, setPagination] = useState<PaginationFilters>({
      activePage: 1,
      pageSize: limit
    });
    const [confirmDeleteModelOpen, setConfirmDeleteModelOpen] = useState(false);
    const [selectedlab, setSelectedLab] = useState<Lab | null>(null);
    const { useDeleteLab } = useLabMutations();
    const { response, isLoading } = useFilterGetAllLabs(
      filter,
      createdForId,
      createdById
    );
    const labList =
      selectionType === 'none'
        ? ungroupByTopologies(response || [], createdForId, createdById)
        : response;
    const { total, items } = filterItems([...labList], pagination);
    const totalPages = paginationUtils.getTotalPages({
      pageSize: pagination.pageSize,
      totalCount: total
    });
    const onDelete = async () => {
      if (!selectedlab) {
        return;
      }
      try {
        useDeleteLab.mutate(selectedlab);
      } catch (err) {
        notifier.requestFailed(err);
      }
      setConfirmDeleteModelOpen(false);
    };
    return (
      <div className='cl-labs-table'>
        <LabsTable
          labs={items}
          selectionType={selectionType}
          displayedColumns={displayedColumns}
          isLoading={isLoading}
          onDelete={lab => {
            setSelectedLab(lab);
            setConfirmDeleteModelOpen(true);
          }}
          setPagination={setPagination}
          pagination={pagination}
          activePage={pagination.activePage}
          totalPages={totalPages}
        />
        <Confirm
          size='mini'
          content={`Are you sure you want to delete ${
            selectedlab ? selectedlab.label : 'this lab'
          }?`}
          open={confirmDeleteModelOpen}
          onCancel={() => setConfirmDeleteModelOpen(false)}
          onConfirm={() => onDelete()}
          confirmButton='Delete'
        />
      </div>
    );
  }
);
