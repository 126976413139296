import { capitalize } from 'lodash';
import React from 'react';
import { Checkbox, Table } from 'semantic-ui-react';

import { SelectionType } from '../ContactsTable/SelectionType';

export function CustomTableHeaderRow(props: {
  selectionType: SelectionType;
  indeterminate: boolean;
  onChange: () => void;
  displayedColumns: string[];
}) {
  const selectionColumn = () => {
    if (props.selectionType === 'multi')
      return (
        <Checkbox
          indeterminate={props.indeterminate}
          checked={props.indeterminate}
          onChange={props.onChange}
        />
      );
    else {
      return <></>;
    }
  };

  return (
    <Table.Row>
      {props.selectionType !== 'none' && (
        <Table.HeaderCell collapsing>{selectionColumn()}</Table.HeaderCell>
      )}
      {props.displayedColumns.map((col, index) => (
        <Table.HeaderCell collapsing key={index}>
          {capitalize(col)}
        </Table.HeaderCell>
      ))}
    </Table.Row>
  );
}
