import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'semantic-ui-react';

import { Lab } from 'types';

import { setCurrentLab, setSidebarIsContentType } from 'features/labs';

import './styles.less';

type Props = {
  readonly lab: Lab;
};

export const LabSidebarContent: React.FC<Props> = memo(({ lab }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <div className='content-div'>Contents</div>
      {lab.labTemplate.labGuideUrls.map((doc, index) => (
        <Card
          key={index}
          link
          className='content-card'
          fluid
          onClick={() => {
            dispatch(setSidebarIsContentType(true));
            dispatch(
              setCurrentLab({
                ...lab,
                progress: {
                  currentPage: index,
                  totalPages: lab.progress.totalPages
                }
              })
            );
          }}
        >
          <Card.Description className='content-font'>
            {doc.name}
          </Card.Description>
        </Card>
      ))}
    </div>
  );
});
