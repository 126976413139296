export const CONTACT_SELECTED = 'CONTACT_SELECTED';
export const CONTACTS_LOADED = 'CONTACT_LOADED';

export const DEPARTMENTS = 'DEPARTMENTS';
export const TOPOLOGIES = 'TOPOLOGIES';
export const DATEINPUT = 'DATEINPUT';
export const FILTERINPUT = 'FILTERINPUT';
export const TOPACTIVE = 'TOPACTIVE';
export const TOGGLEUI = 'TOGGLEUI';
export const FIELDVALUES = 'FIELDVALUES';
