import { isEmpty } from 'lodash';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Progress } from 'semantic-ui-react';

import { getUTCFromNow } from 'utils';
import { calculateLabProgress } from 'utils/labs';

import { RootState } from 'Reducers/contactReducer';

import { LabSidebarContent } from './LabSidebarContent';
import { LabSidebarDetails } from './LabSidebarDetails';

export const LabSidebar: React.FC = memo(() => {
  const lab = useSelector((state: RootState) => state.lab.lab);
  const isContent = useSelector((state: RootState) => state.lab.isContent);
  if (isEmpty(lab)) return <></>;

  return (
    <>
      <div className='label-text'>{lab.label}</div>
      <div className='description-text'>
        Expiration Time: {getUTCFromNow(lab?.expiresAt)}
      </div>
      <div className='progress-bar-container'>
        <div className='progress-bar-text'>Lab Progress</div>
        <div className='progress-bar'>
          <Progress
            className='bar'
            data-testid='progress-bar'
            percent={calculateLabProgress(lab)}
            size='small'
            color='green'
            inverted
          />
          <div className='progress-percent'>{calculateLabProgress(lab)}%</div>
        </div>
      </div>
      {isContent && <LabSidebarContent lab={lab} />}
      {!isContent && <LabSidebarDetails lab={lab} />}
    </>
  );
});
